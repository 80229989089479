import Vue from 'vue';
import {required, email, requiredIf, sameAs} from 'vuelidate/lib/validators';
import {isPhone} from '../../../common/utils/validators';

import template from './index.html';

import {Request} from '../../../common/classes/request';
class FormRequest extends Request{
    constructor(data) {
        super('/ajax/country-subscribe', 'POST', data);
    }
}

export default Vue.component('form-notify', {
    template,
    props: ['countryId', 'data'],
    validations: {
        form: {
            firstName: {required},
            lastName: {required},
            email: {
                email,
                requiredIf: requiredIf(nestedModel => {
                    return !nestedModel.phone;
                })
            },
            phone: {
                isPhone,
                requiredIf: requiredIf(nestedModel => {
                    return !nestedModel.email;
                })
            },
            privacyAgree: {sameAs: sameAs(() => true)},
        },
    },
    data() {
        return {
            loading: false,
            form: {
                country: this.countryId,
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                privacyAgree: false,
            },
        }
    },
    mounted() {
        this.mergeData();
    },
    computed: {
        disabled() {
            return this.loading;
        },
    },
    methods: {
        mergeData() {
            if (!this.data) return;

            if (this.data.email) this.form.email = this.data.email;
        },
        validate() {
            this.$v.form.$touch();
            return !this.$v.form.$invalid;
        },
        submit() {
            if (!this.validate()) return;

            let request = new FormRequest(this.form);

            request.onComplete = () => {
                this.loading = false;
            };

            request.successHandler = () => {};

            request.onSuccess = () => {
                this.$emit('success');
            };

            this.loading = true;
            request.send();
        },
    }
});