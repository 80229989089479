import {req, withParams} from 'vuelidate/lib/validators/common';
import isUrlValidator from 'validator/lib/isURL';

export const isPhone = withParams(
    {type: 'isPhone'},
    function (value) {
        if (!value) return true;

        let phone = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

        if (value.match(phone)) {
            return true;
        } else {
            return false;
        }
    }
);

export const isUrl = withParams(
    {type: 'isUrl'},
    function (value) {
        if (!value) return true;

        return isUrlValidator(value);
    }
);

export const minLowercaseCharacters = min => withParams(
    {type: 'minLowercaseCharacters'},
    function (value) {
        if (!value) return true;

        let result = value.match(/[a-z]/g);

        if (!result || !result.length) {
            return false;
        }

        if (result.length >= min) {
            return true;
        }

        return false;
    }
);

export const minUppercaseCharacters = min => withParams(
    {type: 'minUppercaseCharacters'},
    function (value) {
        if (!value) return true;

        let result = value.match(/[A-Z]/g);

        if (!result || !result.length) {
            return false;
        }

        if (result.length >= min) {
            return true;
        }

        return false;
    }
);

export const minNumberCharacters = min => withParams(
    {type: 'minNumberCharacters'},
    function (value) {
        if (!value) return true;

        let result = value.match(/\d/g);

        if (!result || !result.length) {
            return false;
        }

        if (result.length >= min) {
            return true;
        }

        return false;
    }
);

export const fileMimetypes = mimetypesArray => withParams(
    {type: 'fileMimetypes', mimetypesArray},

    (value, parentVm) => {
        if (!value) {
            return true;
        }

        let mimetype = value.type;

        if (mimetypesArray.indexOf(mimetype) > -1) {
            return true;
        }

        return false;
    }
);

export const fileSizeBetween = (min, max) => withParams(

    {type: 'fileSizeBetween', min, max},

    (value, parentVm) => {
        if (!value || !value.size) {
            return true;
        }

        let size = value.size;

        return size >= min && size <= max;
    }
);