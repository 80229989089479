import Vue from 'vue';

import template from './index.html';

export default Vue.component('control-checkbox', {
    template,
    props: {
        value: {},
        name: {},
        validation: {},
        visibleError: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valueLocal: this.value,
            touched: false,
        }
    },
    mounted() {},
    watch: {
        value(newVal) {
            if (!this.touched) {
                this.validationTouch();
            }

            this.valueLocal = newVal;
        },
    },
    computed: {
        validationError() {
            if (!this.visibleError) return false;

            if (this.validation && this.validation.$error) {
                return true;
            }

            return false;
        },
    },
    methods: {
        input(event) {
            this.valueLocal = event.target.checked;
            this.$emit('input', this.valueLocal);
            if (!this.touched) {
                this.validationTouch();
            }
        },
        validationTouch() {
            if (this.validation) {
                this.validation.$touch();
                this.touched = true;
            }
        },
    }
});