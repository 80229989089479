import Vue from 'vue';

import template from './index.html';

export default Vue.component('system-messages', {
    template,
    data() {
        return {
            list: [],

            defaultMsg: {
                type: '',
                text: '',
                time: 7000,
            }
        }
    },
    mounted() {
        this.$root.$on('system-message', this.newMsg);
        this.$root.$on('system-message-form-invalid-msg', this.formInvalidDefaultMessage);
        window.appSystemMessage = this.newMsg;

        this.showInitMessages();
    },
    beforeDestroy() {
        this.$root.$off('system-message', this.newMsg);
        this.$root.$off('system-message-form-invalid-msg', this.formInvalidDefaultMessage);
    },
    methods: {
        newMsg(data) {
            let newMsg = Object.assign({
                id: this.list.length + Math.floor(Math.random() * 10000 + Date.now())
            }, this.defaultMsg, data);

            if (data.time !== Infinity) {
                newMsg.timeout = setTimeout(() => {
                    this.remove(newMsg);
                }, newMsg.time);
            }

            this.list.push(newMsg);
        },

        remove(msg) {
            for (let i = 0; i < this.list.length; i++) {
                if (msg.id == this.list[i].id) {
                    clearTimeout(msg.timeout);
                    this.list.splice(i, 1);
                    break;
                }
            }
        },

        showInitMessages() {
            if (!this.$root.serverData || !this.$root.serverData.notifications || !this.$root.serverData.notifications.length) return;

            let notifications = this.$root.serverData.notifications;

            notifications.forEach(item => {
                this.newMsg(item);
            });
        },

        formInvalidDefaultMessage() {
            this.newMsg({
                type: 'danger',
                text: 'Please check the highlighted fields and try submitting the form again.',
            });
        },
    }
});