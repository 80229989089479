import Vue from 'vue';
import queryString from 'query-string';

import template from './index.html';

export default Vue.component('widgets', {
    template,
    data() {
        return {
            inputData: {},
            modalCountryType: false,
            modalNotify: false,
            modalNotifySuccess: false,
            countryId: null,

            modalSignIn: false,
            modalPasswordRecover: false,
        }
    },
    mounted() {
        this.openWidgetsOnLoad();

        this.$root.$on('pre-sign-up', this.startSignUpProcess);
        this.$root.$on('sign-in', this.signIn);
    },
    computed: {},
    methods: {
        openWidgetsOnLoad() {
            if (window.location.pathname === '/sign-in') {
                this.signIn();
            } else if (window.location.pathname === '/pre-sign-up') {
                this.startSignUpProcess();
            }
        },

        startSignUpProcess(data) {
            this.inputData = data;
            this.modalCountryType = true;
        },

        countryTypeSuccess(data) {
            let registrationData = Object.assign({}, this.inputData, data);
            let query = queryString.stringify(registrationData);
            window.location = '/sign-up?' + query;
        },
        countryTypeDenied(data) {
            if (data.country && data.country.id) {
                this.countryId = data.country.id;
            }
            setTimeout(() => {
                this.modalCountryType = false;
                this.modalNotify = true;
            }, 300);
        },
        nofitySuccess() {
            this.modalNotify = false;
            this.modalNotifySuccess = true;
        },

        signIn() {
            this.modalSignIn = true;
        },
        signInSuccess(data) {
            this.modalSignIn = false;

            let search = queryString.parse(window.location.search);
            if (search.ref) {
                window.location = search.ref;
                return;
            }

            if (data.success && data.redirect) {
                window.location = data.redirect;
            }
        },
        signUpFromSignIn(data) {
            this.modalSignIn = false;

            let dataLocal = {};
            if (data && data.email) {
                dataLocal.email = data.email;
            }
            this.startSignUpProcess(dataLocal);
        },

        passwordResetOpen() {
            this.modalSignIn = false;
            this.modalPasswordRecover = true;
        },
        passwordResetSuccess() {
            this.modalPasswordRecover = false;
        },
    }
});