import Vue from 'vue';
import {required} from 'vuelidate/lib/validators';

import template from './index.html';

export default Vue.component('form-country-type', {
    template,
    validations: {
        form: {
            country: {required},
            type: {required},
        },
    },
    data() {
        return {
            form: {
                country: null,
                type: null,
            },
            loading: false,
        }
    },
    mounted() {},
    computed: {
        disabled() {
            return this.loading;
        },
    },
    methods: {
        validate() {
            this.$v.form.$touch();
            return !this.$v.form.$invalid;
        },
        check() {
            if (this.form.type === 'Agency') {
                return true;
            }

            if (this.form.country && this.form.country.is_enabled) {
                return true;
            }

            return false;
        },
        submit() {
            if (!this.validate()) return;

            if (!this.check()) {
                this.$emit('denied', this.form);
                return;
            }

            let data = Object.assign({}, this.form, {country: this.form.country.id});
            this.$emit('success', data);
            this.loading = true;
        },
    }
});