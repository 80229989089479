import Vue from 'vue';

export class Request {
    constructor(url, method, data) {
        if (!this.ajaxUrl || url[0] === '/') {
            this.url = url;
        } else {
            this.url = this.ajaxUrl + url;
        }

        this.method = method;

        if (this.method.toLowerCase() == 'get') {
            this.params = data;
        } else {
            this.body = data;
        }

        this.emulateJSON = false;
        this.headers = {};
        if (data instanceof FormData) {
            this.emulateJSON = true;
            this.headers['Content-Type'] = 'multipart/form-data';
        }

        this.newMsgFn = window.appSystemMessage;
    }

    send() {
        let that = this;

        Vue.http({
            method: this.method,
            url: this.url,
            params: this.params,
            body: this.body,
            emulateJSON: this.emulateJSON,
            headers: this.headers,

            before(request) {
                if (that.previousRequest) {
                    that.previousRequest.abort();
                }

                that.previousRequest = request;
            },
        })
            .then(response => {
                if (this.onSuccess && typeof this.onSuccess == 'function') {
                    this.onSuccess(response.body);
                }

                if (this.onComplete && typeof this.onComplete == 'function') {
                    this.onComplete(response);
                }

                this.successHandler(response);
            })
            .catch(errorResponse => {
                this.errorHandler(errorResponse);

                if (this.onError && typeof this.onError == 'function') {
                    this.onError(errorResponse);
                }

                if (this.onComplete && typeof this.onComplete == 'function') {
                    this.onComplete(errorResponse);
                }
            });
    }

    abort() {
        if (this.previousRequest) {
            this.previousRequest.abort();
        }
    }

    errorHandler(response) {
        if (response.body && response.body.success == false && response.body.errors) {
            if (typeof response.body.errors == 'object') {
                let text = '';
                response.body.errors.forEach(error => {
                    text += '\n' + error;
                });
                this.newMsgFn({
                    type: 'danger',
                    text: text
                });
            } else if (typeof response.body.errors == 'string') {
                this.newMsgFn({
                    type: 'danger',
                    text: response.body.errors
                });
            }
        } else if (response.statusText) {
            this.newMsgFn({
                type: 'danger',
                text: response.statusText
            });
        } else {
            this.newMsgFn({
                type: 'danger',
                text: 'Something went wrong. Please try again.'
            });
        }
    }

    successHandler(response){
        if (response.body.success == true && response.body.message) {
            this.newMsgFn({
                type: 'success',
                text: response.body.message
            });
        } else {
            // this.newMsgFn({
            //     type: 'success',
            //     text: 'Ok!'
            // });
        }
    }
}