import Vue from 'vue';
import {required, email} from 'vuelidate/lib/validators';

import template from './index.html';

import {Request} from '../../../common/classes/request';
class FormRequest extends Request{
    constructor(data) {
        super('/ajax/sign-in', 'POST', data);
    }
}

export default Vue.component('form-sign-in', {
    template,
    props: ['countryId'],
    validations: {
        form: {
            email: {
                required,
                email,
            },
            password: {required},
        },
    },
    data() {
        return {
            loading: false,
            form: {
                email: null,
                password: null,
            },
            passwordShow: false,
        }
    },
    mounted() {},
    computed: {
        disabled() {
            return this.loading;
        },
        passwordControlType() {
            return this.passwordShow ? 'text' : 'password';
        },
    },
    methods: {
        validate() {
            this.$v.form.$touch();
            return !this.$v.form.$invalid;
        },
        submit() {
            if (!this.validate()) return;

            let request = new FormRequest(this.form);

            request.onComplete = () => {
                this.loading = false;
            };

            request.onSuccess = data => {
                this.$emit('success', data);
            };

            this.loading = true;
            request.send();
        },

        signUp() {
            this.$emit('sign-up', this.form);
        },
        forgotPassword() {
            this.$emit('forgot-password');
        },

        showPasswordToggle() {
            this.passwordShow = !this.passwordShow;
        },
    }
});