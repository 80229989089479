import Vue from 'vue';
import VueResource from 'vue-resource';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect';
import InfiniteLoading from 'vue-infinite-loading';

import '../common/utils/privatemode-set';
import './components';

Vue.use(VueResource);
Vue.use(Vuelidate);
Vue.component('multiselect', Multiselect);
Vue.component('InfiniteLoading', InfiniteLoading);

export const appWidgets = new Vue({
    el: '#app-widgets',
    methods: {},
});
window.appWidgets = appWidgets;