import Vue from 'vue';

import template from './index.html';

export default Vue.component('control-input', {
    template,
    props: {
        value: {},
        type: {
            type: String,
            default: 'text'
        },
        name: {},
        label: {
            type: String,
        },
        validation: {},
        visibleError: {
            type: Boolean,
            default: true,
        },
        maxlength: {},
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            default: false,
        },
        showSymbols: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valueLocal: this.value,
            touched: false,
        }
    },
    mounted() {},
    watch: {
        value(newVal) {
            if (!this.touched) {
                this.validationTouch();
            }
            this.valueLocal = newVal;
        },
    },
    computed: {
        controlType() {
            if (this.type === 'phone') {
                return 'text';
            }
            return this.type;
        },
        validationError() {
            if (!this.visibleError) return false;

            if (this.validation && this.validation.$error) {
                return true;
            }

            return false;
        },
        required() {
            if (
                this.validation && this.validation.$params
                && ('required' in this.validation.$params)
            ) {
                return true;
            }

            return false;
        },
        checkShow() {
            if (this.readonly) return false;

            if (!this.valueLocal || !(this.type === 'number' || this.valueLocal.length)) {
                return false;
            }

            if (this.validation) {
                if (this.touched && !this.validation.$error) {
                    return true;
                } else {
                    return false;
                }
            }

            if (this.valueLocal && (this.type === 'number' || this.valueLocal.length)) {
                return true;
            }

            return false;
        },
        showValidationErrorTextEmailPhone() {
            if (
                this.validation && this.validation.$params
                && this.validationError
            ) {
                if (
                    this.validation.$params.email && this.validation.$params.email.type === 'email'
                    && this.validation.email === false
                ) {
                    return true;
                }

                if (
                    this.validation.$params.isPhone && this.validation.$params.isPhone.type === 'isPhone'
                    && this.validation.isPhone === false
                ) {
                    return true;
                }
            }

            return false;
        },
        symbolsCount() {
            if (this.valueLocal && this.valueLocal.length) {
                return this.valueLocal.length;
            }
            return 0;
        },
    },
    methods: {
        input(event) {
            let value = event.target.value;

            // if (this.type === 'number') {
                // let valueTemp = parseFloat(value.replace(',', '.'));
                // if (Number.isFinite(valueTemp)) {
                //     value = valueTemp;
                // }
            // }

            this.valueLocal = value;

            this.$emit('input', this.valueLocal);
            if (!this.touched) {
                this.validationTouch();
            }
        },
        blur() {
            this.validationTouch();
            this.$emit('blur');
        },
        validationTouch() {
            if (this.validation) {
                this.validation.$touch();
                this.touched = true;
            }
        },
    }
});