import Vue from 'vue';

import template from './index.html';

import {Request} from '../../../classes/request';

export default Vue.component('control-select-async-custom', {
    template,
    props: {
        value: {},
        label: {
            type: String,
        },
        validation: {},
        visibleError: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            default: false,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        trackBy: {},
        labelBy: {},
        modelKey: {},

        url: {
            type: String,
            default: '/',
        },

        initialRequest: {
            type: Boolean,
            default: true,
        },
        optionsStatic: {
            type: Array,
            default() {
                return new Array()
            },
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            visible: false,
            
            // listHeight: 0,
            // openDirectionAbove: false,

            valueLocal: this.value,
            touched: false,

            loading: false,
            list: [],
            search: '',
            requestData: {
                search: null,
                perPage: 50,
                page: 1,
            },
            infiniteState: null,
            searchTimeout: null,
            firstToggle: this.initialRequest,
        }
    },
    mounted() {
        this.initRequest();

        if (this.initialRequest) {
            this.$refs.multiselect.toggle();
            this.$nextTick(() => {
                this.$refs.multiselect.toggle();
            });
        }
        this.$nextTick(() => {
            this.visible = true;
        });
    },
    watch: {
        value(newVal) {
            if (!this.touched) {
                this.validationTouch();
            }

            if (!this.modelKey || !newVal) {
                this.valueLocal = newVal;
            } else {
                this.valueLocal = this.getOptionByModelKeyValue(newVal);
            }
        },
        search(newVal) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.requestData.page = 1;
                this.list = [];
                if (this.infiniteState) this.infiniteState.reset();
            }, 350);
        },
    },
    computed: {
        // lineStyle() {
        //     if (this.listHeight) {
        //         if (this.openDirectionAbove) {
        //             return {
        //                 bottom: 0,
        //                 top: '-' + this.listHeight + 'px'
        //             }
        //         } else {
        //             return {
        //                 top: 0,
        //                 bottom: '-' + this.listHeight + 'px'
        //             }
        //         }
        //     }
        //
        //     return null;
        // },
        validationError() {
            if (!this.visibleError) return false;

            if (this.validation && this.validation.$error) {
                return true;
            }

            return false;
        },
        required() {
            if (
                this.validation && this.validation.$params
                && ('required' in this.validation.$params)
            ) {
                return true;
            }

            return false;
        },

        options() {
            return this.optionsStatic.concat(this.list);
        },
    },
    methods: {
        input(value) {
            if (!this.touched) {
                this.validationTouch();
            }

            this.valueLocal = value;

            if (!this.modelKey || !this.valueLocal) {
                this.$emit('input', this.valueLocal);
            } else {
                this.$emit('input', this.valueLocal[this.modelKey]);
            }
        },
        open() {
            // if (!this.firstToggle) {
            //     this.$nextTick(this.detectListViewParams);
            // }

            if (!this.initialRequest || !this.list.length) {
                this.$nextTick(() => {
                    this.$refs.infiniteLoading.attemptLoad();
                });
            }
        },
        close() {
            // this.listHeight = 0;
            // this.openDirectionAbove = false;

            if (this.firstToggle) {
                this.firstToggle = false;
            } else {
                this.validationTouch();
            }
        },
        // detectListViewParams() {
        //     this.openDirectionAbove = this.$refs.multiselect.prefferedOpenDirection === 'above';
        //
        //     this.$nextTick(() => {
        //         this.listHeight = this.$refs.multiselect.$refs.list.clientHeight;
        //     });
        //     setTimeout(() => {
        //         this.listHeight = this.$refs.multiselect.$refs.list.clientHeight;
        //     });
        //     setTimeout(() => {
        //         this.listHeight = this.$refs.multiselect.$refs.list.clientHeight;
        //     }, 300);
        // },
        validationTouch() {
            if (this.validation) {
                this.validation.$touch();
                this.touched = true;
            }
        },
        getOptionByModelKeyValue(value) {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i][this.modelKey] && this.options[i][this.modelKey] === value) {
                    return this.options[i];
                    break;
                }
            }
        },


        initRequest() {
            this.request = new Request(this.url, 'post', {});

            this.request.onError = () => {
                if (this.infiniteState) {
                    this.infiniteState.complete();
                }
            };

            this.request.onComplete = () => {
                this.loading = false;
                if (this.infiniteState) this.infiniteState.loaded();
            };

            this.request.onSuccess = data => {
                // if (!this.firstToggle) {
                //     this.$nextTick(this.detectListViewParams);
                // }

                if (!data || !data.payload) return;

                let payload = data.payload;

                if (payload.length) {
                    this.list = this.list.concat(this.getPayloadMerged(payload));
                }

                if (payload.length < this.requestData.perPage) {
                    if (this.infiniteState) {
                        this.infiniteState.complete();
                    }
                }
            };
        },

        getPayloadMerged(payload) {
            if (!payload || !payload.length) return payload;
            if (!this.optionsStatic || !this.optionsStatic.length) return payload;

            let staticKeys = this.optionsStatic.map(option => {
                return option[this.trackBy];
            });
            let payloadMerged = [];

            payload.forEach(item => {
                if (staticKeys.indexOf(item[this.trackBy]) < 0) {
                    payloadMerged.push(item);
                }
            });

            return payloadMerged;
        },

        getData() {
            if (this.loading) return;

            this.request.body = this.requestData;
            this.request.body.search = this.search;

            this.loading = true;
            this.request.send();
        },

        searchChange(query) {
            this.search = query;
        },

        infiniteHandler($state) {
            if (!this.infiniteState) {
                this.infiniteState = $state;
            }

            this.getData();
            this.requestData.page++;
        },
    }
});