import Vue from 'vue';

import template from './index.html';

export default Vue.component('modal', {
    template,
    props: ['value'],
    data() {
        return {
            selfId: null,
        }
    },
    beforeDestroy() {
        this.$root.$off('modal-close-by-escape', this.modalCloseByEscape);
    },
    mounted() {
        rootApp = this.$root;

        if (!this.$root.modalsOpen) this.$root.modalsOpen = [];

        this.selfId = Date.now() + Math.round(Math.random() * 100);
        this.selfId = this.selfId.toString();
        this.selfId = this.selfId.substring(this.selfId.length - 6, this.selfId.length);

        this.$root.$on('modal-close-by-escape', this.modalCloseByEscape);
    },
    computed: {},
    watch: {
        value(newValue) {
            if (newValue) {
                this.$nextTick(this.tryToFocusControl);
                this.$nextTick(this.globalModalsControl);
            }
        },
    },
    methods: {
        close() {
            this.$emit('input', false);
            this.$nextTick(this.globalModalsControl);
        },

        tryToFocusControl() {
            let control = this.$el.querySelector('input, select, textarea');

            if (control) {
                control.focus();
            }
        },

        globalModalsControl() {
            let index = this.$root.modalsOpen.indexOf(this.selfId);

            if (this.value) {
                if (index < 0) {
                    this.$root.modalsOpen.push(this.selfId);
                }
            } else {
                if (index > -1) {
                    this.$root.modalsOpen.splice(index, 1);
                }
            }
        },

        modalCloseByEscape() {
            if (!this.value) return;

            let index = this.$root.modalsOpen.indexOf(this.selfId);
            if (index < 0) return;

            if (index + 1 >= this.$root.modalsOpen.length) {
                this.close();
            }
        },
    }
});

let rootApp = null;
window.document.addEventListener('keyup', event => {
    if (event.key === 'Escape') {
        rootApp.$emit('modal-close-by-escape');
    }
});